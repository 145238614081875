// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAttikpMXvFEQeWge-nLkvfygpQTw2uVuc",
  authDomain: "blesd-demo-v3.firebaseapp.com",
  databaseURL: "https://blesd-demo-v3-default-rtdb.firebaseio.com",
  projectId: "blesd-demo-v3",
  storageBucket: "blesd-demo-v3.appspot.com",
  messagingSenderId: "440236256395",
  appId: "1:440236256395:web:327fefe71e3772c70ab88e",
  measurementId: "G-LKLM1WWSSB"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);



export default db;
