import React, { useState, useEffect } from 'react';
import { PaymentRequestButtonElement, useStripe } from '@stripe/react-stripe-js';
import { BarLoader } from 'react-spinners';
import axios from 'axios';
import { baseUrl } from './config';

const CheckoutForm = (props) => {
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState(null);

  useEffect(() => {
    if (stripe) {
      console.warn({
        postId: props.postId,
        taggedProfit: props.taggedProfit,
        voteCount: props.voteCount,
        tipAmount: props.tipAmount
      })
      const pr = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: props.currencyLabel,
          amount: parseInt(props.totalAmount * 100),
        },
        requestPayerEmail: true,
      });

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then(result => {
        if (result) {
          setPaymentRequest(pr);

          pr.on('paymentmethod', async (ev) => {

            //create payment intent
            const response = await axios.post(baseUrl + '/donateBitesWeb', {
              postId: props.postId,
              taggedProfit: props.taggedProfit,
              voteCount: props.voteCount,
              tipAmount: props.tipAmount,
              os: 'web',
            })
            const clientSecret = response.data.client_secret;


            // Confirm the PaymentIntent without handling potential next actions (yet).
            const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(
              clientSecret,
              { payment_method: ev.paymentMethod.id },
              { handleActions: false }
            );




            if (confirmError) {
              // Report to the browser that the payment failed, prompting it to
              // re-show the payment interface, or show an error message and close
              // the payment interface.
              ev.complete('fail');
              alert('Payment failed');
            } else {
              // Report to the browser that the confirmation was successful, prompting
              // it to close the browser payment method collection interface.
              ev.complete('success');
              // Check if the PaymentIntent requires any actions and if so let Stripe.js
              // handle the flow. If using an API version older than "2019-02-11"
              // instead check for: `paymentIntent.status === "requires_source_action"`.
              if (paymentIntent.status === "requires_action") {
                // Let Stripe.js handle the rest of the payment flow.
                const { error } = await stripe.confirmCardPayment(clientSecret);
                if (error) {
                  // The payment failed -- ask your customer for a new payment method.
                  alert('Payment failed');
                } else {
                  // The payment has succeeded.
                  alert('Payment succeeded');
                  props.onSuccess()

                }
              } else {
                alert('Payment succeeded');
                // The payment has succeeded.
                props.onSuccess()

              }
            }
          });




        }
      });
    }


  }, [stripe]);

  if (paymentRequest) {
    return <div style={{ width: '100%' }}><PaymentRequestButtonElement options={{ paymentRequest }} /></div>
  }

  return <BarLoader color="rgb(78,173,254)"
    width={150}

  />;
}

export default CheckoutForm;