import "../node_modules/video-react/dist/video-react.css";
// import ReactPlayer from 'react-player'

import './App.css';
import { useParams } from 'react-router-dom';
import db from './Firebase';
import { createRef, useEffect, useState } from 'react';
import { doc, getDoc, collection, getDocs } from "firebase/firestore";
import { Helmet } from 'react-helmet';
import moment from 'moment'
import { isMobile } from 'react-device-detect';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { completeTransaction } from './services/random';
import { baseUrl } from './config';
import axios from 'axios'
import { Player, ControlBar, source, BigPlayButton } from 'video-react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';
const stripePromise = loadStripe('pk_live_FPfzu02YxySMFdt3C9voiG4W003CNbITqC');

//enable google pay and apple pay

const options = {
  mode: 'setup',
  // amount: 1099,
  currency: 'usd',
  // Fully customizable with appearance API.
  appearance: {/*...*/ },
  // payment_method_types: ['card', 'apple_pay', 'google_pay'],
}

function App() {
  const playerRef = createRef();
  let { id } = useParams();
  const [data, setData] = useState(null);
  const [creator, setCreator] = useState(null);
  const [nonprofit, setNonprofit] = useState(null);
  const [comments, setComments] = useState([]);
  const [creatorsData, setCreatorsData] = useState({});
  const [invalidId, setInvalidId] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedBites, setSelectedBites] = useState(10);
  const [customBites, setCustomBites] = useState("");
  const [tipAmount, setTipAmount] = useState(0)
  const [tipAmountCustom, setTipAmountCustom] = useState("")
  const [config, setConfig] = useState({})
  const [isPlaying, setIsPlaying] = useState(false)
  const onToggleModal = () => setOpenModal(!openModal);

  useEffect(() => {
    getData();
    if (!id) {
      setInvalidId(true);
    } else {
      getConfig();
    }

  }, [])




  const getConfig = async () => {
    const configRef = doc(db, "configs", "Donutap");
    const configSnap = await getDoc(configRef);
    if (configSnap.exists()) {

      console.log("Config data:", configSnap.data());
      setConfig(configSnap.data());
    }


  }


  const getAmountToCharge = (amount) => {
    return (parseFloat(amount) + 0.30) / (1 - 0.044)
  }


  const getTotalAmount = () => {
    let totalAmount = 0.00;
    if (selectedBites !== "custom") {
      totalAmount = selectedBites * config.currencyToUsd
    }
    else if (customBites) {
      totalAmount = parseFloat(customBites) * config.currencyToUsd
    }
    if (tipAmount !== "custom") {
      totalAmount = totalAmount + (tipAmount * config.currencyToUsd)
    }
    else if (tipAmountCustom) {
      totalAmount = totalAmount + (parseFloat(tipAmountCustom) * config.currencyToUsd)
    }
    return getAmountToCharge(totalAmount).toFixed(2);
  }

  const getCurrencyLabel = () => {
    let totalBites = 0
    if (selectedBites !== "custom") {
      totalBites = selectedBites
    }
    else if (customBites) {
      totalBites = customBites
    }
    if (tipAmount !== "custom") {
      totalBites = parseInt(totalBites) + parseInt(tipAmount)
    }
    else if (tipAmountCustom) {
      totalBites = parseInt(totalBites) + parseInt(tipAmountCustom)
    }
    return totalBites + " " + config.currency;
  }




  useEffect(() => {
    if (data && playerRef.current) {
      console.warn("isPlaying", isPlaying)
      // setIsPlaying(true);
      playerRef.current.play();
    }
  }, [data])


  const getData = async () => {
    //get post with id
    const docRef = doc(db, "post", id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      setData(docSnap.data());
      //get creator with id

      const creatorRef = doc(db, "user", docSnap.data().creator);
      const creatorSnap = await getDoc(creatorRef);

      if (creatorSnap.exists()) {
        console.log("creator Document data:", creatorSnap.data());
        setCreator(creatorSnap.data());
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }

      //get nonprofit with id
      const nonprofitRef = doc(db, "nonprofit", docSnap.data().taggedProfit);
      const nonprofitSnap = await getDoc(nonprofitRef);

      if (nonprofitSnap.exists()) {
        console.log("Nonprofit Document data:", nonprofitSnap.data());
        setNonprofit(nonprofitSnap.data());
        if (isMobile) {
          window.location.assign('donutap://post/' + id)
        }
      }
      else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }

      //get comments with id post/id/comments
      const commentsRef = collection(db, "post", id, "comments");
      const commentsSnap = await getDocs(commentsRef);
      let comments = [];
      commentsSnap.forEach((doc) => {
        console.log(doc.id, " => ", doc.data());
        comments.push(doc.data());
      }
      );
      setComments(comments);

      //get creators data
      let creatorsData = {};
      for (let i = 0; i < comments.length; i++) {
        if (comments[i].creator in creatorsData) {
          continue;
        }
        const creatorRef = doc(db, "user", comments[i].creator);
        const creatorSnap = await getDoc(creatorRef);

        if (creatorSnap.exists()) {
          console.log("creator Document data:", creatorSnap.data());
          creatorsData[comments[i].creator] = creatorSnap.data();
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      }
      setCreatorsData(creatorsData);


    }
    else {
      setInvalidId(true);
    }

  }

  const getStyledDescription = (desc, username) => {
    let description = desc || data?.description || "";
    description = description.replace(/\[(\w+)\]\(\1\)/g, "$1");
    let preText = "";
    let postText = "";
    let tagText = "";
    let splittedText = description.split("$");
    preText = splittedText[0];
    postText = splittedText.length > 0 ? splittedText[1]?.split(" ")[1] : "";
    tagText = splittedText.length > 0 ? splittedText[1]?.split(" ")[0] : "";
    return (
      <div style={{}}>
        <p style={{}} ><b>{username}:</b> {preText} <span style={{ fontWeight: 'bold' }}>{tagText ? "$" + tagText : ""}</span> {postText}</p>
      </div>
    )
  }

  const screenAspectRatio = window.innerWidth / (window.innerHeight - 75);
  const videoAspectRatio = 1080 / 1920;
  // const videoWidth = screenAspectRatio < videoAspectRatio ? window.innerWidth : (window.innerHeight - 75) * videoAspectRatio;
  // const videoHeight = screenAspectRatio < videoAspectRatio ? window.innerWidth / videoAspectRatio : (window.innerHeight - 75);

  const videoWidth = window.innerWidth
  //calculate height based on videoWidth and videoAspectRatio
  const videoHeight = videoWidth / videoAspectRatio


  return (
    <Elements stripe={stripePromise} options={options}>
      <Helmet>
        <title>Donutap</title>
      </Helmet>
      <div className="App">
        <div style={{
          background: 'rgba(78,173,254,0.4)',
          filter: 'blur(3px)',
          height: 70, position: 'fixed', zIndex: 100000,
          top: 0,
          width: '100%',
        }} />
        <div style={{
          background: 'rgba(78,173,254,0.2)',
          borderBottom: 'solid rgba(255,255,255,0.1) 1px',
          height: 70, position: 'fixed', zIndex: 100001,
          width: '100%',
          // filter: 'blur(8px)'
        }}>
          <img
            style={{ height: 50, margin: 10, }}
            src={require('./assets/donutap_logo.png')} />
        </div>
        <div style={{
          display: 'flex', flex: 1,
          paddingTop: isMobile ? 0 : 70,
          flexDirection: 'column', alignItems: 'center',
          overflowX: 'hidden',
        }}>
          <div style={{
            // alignSelf: 'center',
            width: videoWidth,
            height: videoHeight,
            position: 'relative',
          }}>

            {/* {data ? (
            <ReactPlayer
              url={(data?.media[0])?.replace('https://donutap.s3.amazonaws.com/', 'https://d75cj7t4xkz73.cloudfront.net/')}
              controls={true}
              // onReady={() => setIsPlaying(true)}
              // playing={true}
              loop
              // volume={0}
              width='100%'
              height={'100%'}
            // height='100%'
            />
          ) : !invalidId && (
            <div style={{ width: '100%', height: '100%', backgroundColor: 'black', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <img src={require('./assets/donut.gif')} style={{ width: '100', height: '100', objectFit: 'cover' }} />

            </div>
          )} */}

            {data ? (
              <Player
                playsInline
                height={'100%'}
                width={'100%'}
                // autoPlay={true}
                loop={true}
                ref={playerRef}

                poster={(data?.media[1])?.replace('https://donutap.s3.amazonaws.com/', 'https://d75cj7t4xkz73.cloudfront.net/')}
                src={(data?.media[0])?.replace('https://donutap.s3.amazonaws.com/', 'https://d75cj7t4xkz73.cloudfront.net/')}
              >
                <BigPlayButton position="center" />

                <ControlBar disableCompletely disableDefaultControls />
              </Player>
            ) : !invalidId && (
              <div style={{ width: '100%', height: '100%', backgroundColor: 'rgb(78,173,254)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img src={require('./assets/donut.gif')} style={{ width: '100', height: '100', objectFit: 'cover' }} />
              </div>
            )}






            <div style={{ position: 'absolute', bottom: -1, width: '100%', background: "linear-gradient(rgba(78,173,254,0), rgba(78,173,254,1))", minHeight: 70 }}>
              {/* <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', }}>
                <div style={{ paddingLeft: 20 }}>
                  {data && (
                    <img src={creator?.photoURL.replace('https://donutap.s3.amazonaws.com/', 'https://d75cj7t4xkz73.cloudfront.net/')}
                      style={{ height: 50, width: 50, borderRadius: 28, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', borderColor: 'rgb(78,173,254)', borderWidth: 4, borderStyle: 'solid', backgroundColor: 'rgb(78,173,254)' }}
                    />
                  )}

                </div>

                <div style={{ paddingRight: 20 }}>
                  {
                    nonprofit?.photoURL ? (
                      <img src={nonprofit?.photoURL.replace('https://donutap.s3.amazonaws.com/', 'https://d75cj7t4xkz73.cloudfront.net/')}
                        style={{ height: 50, width: 50, borderRadius: 28, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', borderColor: 'white', borderWidth: 4, borderStyle: 'solid', backgroundColor: 'white' }}
                      />

                    ) : (null)
                  }
                </div>
              </div> */}
              {/* <div style={{ color: 'white', textAlign: 'left', paddingLeft: 15, paddingRight: 15, paddingBottom: 15 }}>
                <h3>{creator?.username}</h3>
              </div> */}
              {data && (
                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 10 }}>
                  <div
                    onClick={() => {
                      setOpenModal(true);
                    }}
                    style={{ backgroundColor: 'white', borderRadius: 30, padding: 12, paddingLeft: 30, paddingRight: 30, cursor: 'pointer', userSelect: 'none' }}>
                    <b style={{ fontWeight: '400', paddingBottom: 15 }} >Make a Donation to</b><br />
                    <b>{nonprofit?.name}</b>
                  </div>
                </div>
              )}

            </div>




          </div>

          {invalidId && (
            <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
              <h2 style={{ color: 'white', textAlign: 'center' }}>Unable to fetch post</h2>
            </div>
          )}

          {/* {data && isMobile && (
          <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 10, marginTop: 10 }}>
            <div
              onClick={() => {
                setOpenModal(true);
              }}
              style={{ backgroundColor: 'white', borderRadius: 30, padding: 12, paddingLeft: 30, paddingRight: 30, cursor: 'pointer', userSelect: 'none' }}>
              <b>Make a Donation</b>
            </div>
          </div>
        )} */}

          {data && (
            <div style={{ height: '100%', flex: 1, textAlign: 'left', display: 'flex', flexDirection: 'column', }}>
              <div style={{
                padding: 10, borderBottom: 'solid rgba(255,255,255,0.2) 1px', display: 'flex', alignItems: 'center'
              }}>

                <img src={creator?.photoURL.replace('https://donutap.s3.amazonaws.com/', 'https://d75cj7t4xkz73.cloudfront.net/')}
                  style={{ height: 44, width: 44, borderRadius: 28, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', borderColor: 'white', borderWidth: 3, borderStyle: 'solid', backgroundColor: 'rgb(78,173,254)' }}
                />
                <div>
                  {/* <h4 style={{ paddingLeft: 10, margin: 0 }}>{creator?.username}</h4> */}
                  <p style={{ paddingLeft: 10, color: 'white' }}>
                    {getStyledDescription(data?.description, creator?.username)}
                    <p style={{ fontSize: 10 }}>{moment.unix(data?.creation.seconds).startOf('s').fromNow()}</p>
                  </p>
                </div>
              </div>
              {comments?.map((comment, index) => {

                return (
                  <div style={{ padding: 10, paddingBottom: 5, display: 'flex', alignItems: 'center' }}>
                    <img
                      style={{ height: 42, width: 42, borderRadius: 28, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', borderColor: 'white', borderWidth: 3, borderStyle: 'solid', backgroundColor: 'rgb(78,173,254)' }}
                      src={creatorsData[comment.creator]?.photoURL.replace('https://donutap.s3.amazonaws.com/', 'https://d75cj7t4xkz73.cloudfront.net/')} />
                    <div>
                      <p style={{ paddingLeft: 10, color: 'white' }}>
                        <b>{creatorsData[comment.creator]?.username}:</b> {comment?.comment}
                        <p style={{ fontSize: 10, opacity: 0.8 }}>{moment.unix(comment?.creation.seconds).startOf('s').fromNow()}</p>

                      </p>
                      <div>
                      </div>
                    </div>
                  </div>
                )
              })
              }
              <div style={{ paddingBottom: 20, paddingTop: 10, borderTop: 'solid rgba(255,255,255,0.2) 1px' }}>
                <div style={{ padding: 10, textAlign: 'center', color: 'white' }}>
                  <span>Download the app at</span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-around', width: isMobile ? '100%' : '70%', marginLeft: isMobile ? "0%" : '15%', alignItems: 'center' }}>
                  <img
                    style={{ margin: 5 }}
                    onClick={() => {
                    }}
                    src={require('./assets/AppleStoreButton.png')} style={{ height: 60, objectFit: 'cover', alignSelf: 'center', cursor: 'pointer' }} />
                  <img
                    style={{ margin: 5 }}
                    onClick={() => {
                    }}
                    src={require('./assets/GooglePlayButton.png')} style={{ height: 50, objectFit: 'cover', alignSelf: 'center', cursor: 'pointer' }} />
                </div>


              </div>
            </div>
          )}

        </div>

        <Modal open={openModal} onClose={onToggleModal} center
          styles={{
            root: {
              zIndex: 1000000
            },
            modal: {
              padding: 20,
              maxWidth: 400
            }
          }}
        >
          <h2 style={{ marginBottom: 0 }} >Make a Donation</h2>
          <p style={{ marginTop: 2 }}>1 {config.currencySingular} equals ${config.currencyToUsd}</p>
          <hr />
          <p style={{ padding: 10 }}>
            How much would you like to donate?
          </p>
          <div style={{ flexDirection: 'row', paddingHorizontal: 10, flexWrap: 'wrap', justifyContent: 'center', display: 'flex' }}>
            {[10, 25, 50, 100].map((item, index) => {
              return (
                <div
                  onClick={() => {
                    setSelectedBites(item)
                  }}
                  style={{ padding: 8, margin: 6, borderRadius: 20, alignItems: 'center', backgroundColor: selectedBites === item ? 'rgb(85,169,255)' : 'rgba(0,0,0,0.1)', cursor: 'pointer' }}
                >
                  <p style={{ color: selectedBites === item ? 'white' : 'black', padding: 0, margin: 0 }}>{item} {config.currency}</p>
                </div>
              )
            })}

            <div
              onClick={() => {
                setSelectedBites("custom")
              }}
              style={{ padding: 8, paddingHorizontal: 15, margin: 6, borderRadius: 20, alignItems: 'center', backgroundColor: selectedBites == 'custom' ? 'rgb(85,169,255)' : 'rgba(0,0,0,0.1)', cursor: 'pointer' }}
            >
              <p style={{ color: selectedBites == 'custom' ? 'white' : 'black', padding: 0, margin: 0 }}>Custom Amount</p>
            </div>


          </div>
          {selectedBites == 'custom' && <input
            aria-valuemax={100}
            aria-valuemin={0}
            aria-valuenow={customBites}
            placeholder="Enter Amount"
            keyboardType={'number-pad'}
            value={customBites}
            onChange={(e) => {
              setCustomBites(e.target.value)
            }}
            style={{ padding: 15, backgroundColor: 'rgba(0,0,0,0.1)', margin: 20, borderRadius: 20, border: 'none' }} />}
          <hr />

          <p style={{ padding: 10 }}>
            Would you like to tip the Donutap Foundation?
          </p>

          <div>
            <div style={{ flexDirection: 'row', paddingHorizontal: 10, flexWrap: 'wrap', justifyContent: 'center', display: 'flex' }}>
              {[0, 1, 10, 50, 100].map((item, index) => {
                return (
                  <div
                    onClick={() => {
                      setTipAmount(item)
                    }}
                    style={{ padding: 8, paddingHorizontal: 15, margin: 6, borderRadius: 20, alignItems: 'center', cursor: 'pointer', backgroundColor: tipAmount == item ? 'rgb(85,169,255)' : 'rgba(0,0,0,0.1)' }}
                  >
                    <p style={{ flex: 1, color: tipAmount == item ? 'white' : 'black', padding: 0, margin: 0 }}>{item} {config.currency}</p>
                  </div>
                )
              })}

              <div
                onClick={() => {
                  setTipAmount("custom")
                }}
                style={{ padding: 8, paddingHorizontal: 15, margin: 6, borderRadius: 20, alignItems: 'center', cursor: 'pointer', backgroundColor: tipAmount == 'custom' ? 'rgb(85,169,255)' : 'rgba(0,0,0,0.1)' }}
              >
                <p style={{ flex: 1, color: tipAmount == 'custom' ? 'white' : 'black', padding: 0, margin: 0 }}>Custom Amount</p>
              </div>
            </div>

          </div>

          {tipAmount == 'custom' && <input
            aria-valuemax={100}
            aria-valuemin={0}
            aria-valuenow={tipAmountCustom}
            placeholder="Enter Amount"
            keyboardType={'number-pad'}
            value={tipAmountCustom}
            onChange={(e) => {
              setTipAmountCustom(e.target.value)
            }}
            style={{ padding: 15, backgroundColor: 'rgba(0,0,0,0.1)', margin: 20, borderRadius: 20, border: 'none' }} />}

          {/* <p>{getTotalAmount()}</p> */}
          <hr />
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', flexWrap: 'wrap' }}>

            {openModal && (
              <CheckoutForm
                key={(selectedBites == 'custom' ? customBites : selectedBites) + (tipAmount == 'custom' ? tipAmountCustom : tipAmount)}
                config={config}
                onSuccess={() => {
                  onToggleModal()
                }}
                currencyLabel={getCurrencyLabel()}
                totalAmount={getTotalAmount()}
                postId={id}
                taggedProfit={data?.taggedProfit}
                voteCount={selectedBites == 'custom' ? customBites : selectedBites}
                tipAmount={tipAmount == 'custom' ? tipAmountCustom : tipAmount}
              />
            )}

            {/* <form onSubmit={(val) => {
              console.log(val)
            }} >
              <PaymentElement
                mode="setup"
                // amount={getTotalAmount()}
                currency={"USD"}

              />

              <button type="submit">Pay</button>
            </form> */}



          </div>

        </Modal >


      </div >
    </Elements>
  );
}

export default App;
