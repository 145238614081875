import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_live_FPfzu02YxySMFdt3C9voiG4W003CNbITqC');

const root = ReactDOM.createRoot(document.getElementById('root'));
const options = {
  // passing the client secret obtained in step 3
  clientSecret: '{{CLIENT_SECRET}}',
  // Fully customizable with appearance API.
  appearance: {/*...*/ },
};
// const router = createBrowserRouter([
//   {
//     path: "/",
//     element: <App />,
//     errorElement: <div>404</div>,
//   }, ,
//   {
//     path: "/:id",
//     element: <App />,
//     errorElement: <div>404</div>,
//   },
// ]);


root.render(
  <React.StrictMode>

    <Router>
      <Switch>
        <Route path="/:id">
          <App />
        </Route>
        <Route path="/">
          <App />
        </Route>
      </Switch>

    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
